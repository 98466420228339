/** 
 * PartnerSDK javascript api version 0.2
 * 
 * Use namespace 'tla' for latest api version
 * or 'tla_vN' for a version freeze. 
 *
 * See documentation for more on version handling.
 * 
 * @typedef {Namespace/object} tla_v0
 */
var tla_v0 = {

    cb0: null,
    cb1: null,
    cb2: null,
    ww:  null,
    
    status_ok: "ok",
    
    /** Is this version of the tla api deprecated? 
     * @type {flag}
     */
    apiDeprecated: false,
    
    /** Log trace messages in browser console?
     * @type {flag}
     */
    log2console: false,
    
    /** System Event callback handling */
    ev: {

        /** 
        * <p>events: all</p>
        * <p>This event has a unique event data structure where type of event is encoded.</p>
        * @type {const}
        */
        Alltype: "X",
        
        /**
        * <p>events: rfid/fob</p>
        * @type {const}
        */
        Rfid:     "ri",
        
        /**
        * <p>events: camera and image</p
        * @type {const}
        */
        Camera:    "cam",
        
        /**
        * <p>events: fingerprint identification</p>
        * @type {const}
        */
        Fingerprint: "fi",
        
        /**
        * <p>events: external backend server</p>
        * @type {const}
        */
        External:    "ext",
        
        /**
        * <p>events: network connection</p>
        * @type {const}
        */
        Network:     "netw",
        
        /**
        * <p>events: terminal local node app server</p>
        * @type {const}
        */
        Localnode:    "lnode",
        
        /**
        * <p>events: user message</p>
        * @type {const}
        */
        Usermsg: "um",
        
        /**
        * <p>events: template was deleted</p>
        * @type {const}
        */
        TemplateDeleted: "dl",
        
        /**
        * <p>events: fingerprint enrollment phase 1 completed</p>
        * @type {const}
        */
        FingerprintEnroll1: "fe1",
        FingerprintEnroll2: "fe2",
        FingerprintEnroll3: "fe3",
        FingerprintEnroll4: "fe4",
        FingerprintEnroll5: "fe5",
        FingerprintListing: "fl",

        /**
        * System Event callback parameter JSON structure
        * @typedef EventJson
        * @type {object}
        * @property {Integer} EventJson.sender - Sender type, (=Callback enum)
        * @property {Object}  EventJson.load - Payload data from event source
        */
        
        /**
        * Callback function to capture System Events
        * @callback tla~eventcb
        * @param {event} - EventJson or EventJson.load
        * @return {void}
        */
            
        /** Register a function as callback
        * @param {number} evid - what events wanted. Use ev-constants.
        * @param {tla~eventcb} f - callback to register.
        * @return {void}
        * @type {method}
        */
        registercb: function(evid, f) {
            if (tla_v0.log2console)
                console.log("tla registering callback for event " + evid);
            
            switch(evid) {
                case tla_v0.ev.Alltype:
                    tla_v0.cb0 = f;
                    break;
                case tla_v0.ev.Camera:
                    tla_v0.cb2 = f;
                    break;
            };
            tla_v0.startww();
        },
        
        eventidCheck: function(event, idc) {
            return event.data[0] == idc;
        },

        eventid: function(event) {
            return event.data[0];
        },

        eventstatusCheck: function(event, stat) {
            return event.data[1] == stat;
        },

        eventstatus: function(event) {
            return event.data[1];
        },

        eventpayload: function(event) {
            return event.data[2];
        }
        
    },
    
    // Implementation
    imp: {
        dispatch: function(msg) {
            tla_v0.ww.postMessage(msg);
        }
    },
    
    /** Starting the worker that talks southbound
     * @return {void}
     * @type {method}
     */
    startww: function () {
        if (tla_v0.ww !== null)
            return;

        tla_v0.ww = new Worker("assets/js/brightec/worker.js");
        
        tla_v0.ww.onmessage = function(event) {
            if (tla_v0.log2console)
                console.log("tla callback message received");
            
            if (tla_v0.cb0 !== null) {
                tla_v0.cb0(event);
                return;
            }
            
            tla_v0.cb2(event);
        };
        tla_v0.ww.onerror = function(event){
            if (tla_v0.log2console)
                console.log("tla worker error");
        };
    },

    /** Fingerprint handling */
    fp: {
        /**
         * Leave fingerprint identify mode
         * @return {void}
         * @type {method}
        */
        leaveIdentifyMode: function() {
            tla_v0.imp.dispatch({op: "fpi", identify: "off"});
        },
        
        /**
         * Enter fingerprint identify mode
         * @return {void}
         * @type {method}
        */
        enterIdentifyMode: function() {
            tla_v0.imp.dispatch({op: "fpi", identify: "auto"});
        },
        
        /**
         * Enroll new User with a first Fingerprint
         * @param {string} templateId - A Global User ID with fingertipix
         * @return {void}
         * @type {method}
        */
        enrollNewUser: function(templateId) {
            tla_v0.imp.dispatch({ev: "fce", variant:1, op1: templateId});
        },
        
        /**
         * Enroll new User with a first Fingerprint, split arguments
         * @param {number} globalID - A new Global User ID.
         * @param {number} fingertipIx - Which fingertip to add
         * @return {void}
         * @type {method}
        */
        enrollNewUserSplit: function(globalID, fingertipIx) {
            tla_v0.imp.dispatch({ev: "fe", op1: globalID, op2: fingertipIx});
        },
        
        /**
         * Enroll existing User adding a Fingerprint
         * @param {string} templateId - A Global User ID with fingertipix
         * @return {void}
         * @type {method}
        */
        enrollExistingUser: function(templateId) {
            tla_v0.imp.dispatch({op: "fpEnrollEx", global: globalID, index: fingertipIx});
        },
        
        /**
         * Enroll existing User adding a Fingerprint
         * @param {number} globalID - An existing Global User ID.
         * @param {number} fingertipIx - Which fingertip to add
         * @return {void}
         * @type {method}
        */
        enrollExistingUserSplit: function(globalID, fingertipIx) {
            tla_v0.imp.dispatch({op: "fpEnrollEx", global: globalID, index: fingertipIx});
        },
        
        /** 
         * Delete already enrolled Users single fingertip Template
         * @param {string} templateId - A Global User ID with fingertipix
         * @return {void}
         * @type {method}
        */
        deleteOneTemplate: function(templateId) {
            tla_v0.imp.dispatch({op: "fcd", variant:1, op1: templateId});
        },
        
        /** 
         * Delete already enrolled Users single fingertip Template
         * @param {number} globalID - Global User ID.
         * @param {number} fingertipIx - Which fingertip template to delete, 0 (zero) for all
         * @return {void}
         * @type {method}
        */
        deleteOneTemplateSplit: function(globalID, fingerIx) {
            tla_v0.imp.dispatch({op: "fcd", variant:2, global: globalID, index: fingertipIx});
        },
        
        /** 
         * Clear all existing Users with associated data
         * @return {void}
         * @type {method}
        */
        clearAllUsers: function() {
            tla_v0.imp.dispatch({op: "fpClearAll", token: "Yes"});
        },
        
        /** 
         * Request listing from template module
         * @param {string} name of listing, "templateid1".
         * @return {void}
         * @type {method}
        */
        requestListing: function(listname) {
            tla_v0.imp.dispatch({op: "fcl", name: listname});
        },
        
        /**
         * Request a fingerprint status report
         * @return {void}
         * @type {method}
        */
        requestStatus: function() {
            tla_v0.imp.dispatch({op: "fpStatus"});
        }
    },

    /** Camera handling */
    cam: {
        /** 
        * Dispatch order to take a picture</p>
        * @return {void}
        * @type {method}
        */
        takePicture: function() {
            tla_v0.imp.dispatch({op: "camTakePicture"});
        }
    },
    
    /** Netnode handling */
    node: {

        /**
        * TBD
        * @return {void}
        * @type {method}
        */
        sync: function() {
            tla_v0.imp.dispatch({op: "nodeSync"});
        }
    },
    
    /** Network handling */
    nw: {
        /**
        * Request network-status report
        * @return {void}
        * @type {method}
        */
        requestStatus: function() {
            tla_v0.imp.dispatch({op: "netStatus"});
        }
    },
    
    /** Call local server
     * <p>Asynchronously call a terminal local service ajax-style with json parameters</p>
     * @param {string} scriptpath - Name of worker php script, a path with a filename
     * @param {object} params - JSON object holding all parameters input to script
     * @param {function} cb - Function callback to receive returned data and status
     * @return {object} XMLHttpRequest
     * @type {method}
     */
    localJsonCall: function(scriptpath, params, cb) {
        var xhr = new XMLHttpRequest();
        
        xhr.onload = function() {
            if (tla_v0.log2console)
                console.log("tla json response received " + String(xhr.responseText));
            var resp = JSON.parse(xhr.responseText);
            cb(resp);
        }
        xhr.onerror = function () {
            if (tla_v0.log2console)
                console.log("tla json response error");
            cb({status: "Fail"});
        }
        xhr.open("POST", "http://localhost.local/app/" + scriptpath, true);
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhr.send(JSON.stringify(params));
    }

}

/** 
 * Namespace 'tla' for latest api version
 * 
 * @typedef {Object} tla
 */
var tla = tla_v0;
